import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { PublicModule } from "../public.module";
import { AuthService } from "./auth.service";
import { NbToastrService } from "@nebular/theme";

@Injectable({
  providedIn: "root",
})
export class MainService {
  public user: any;
  public empresa: any;
  public headers: Headers;
  public baseUrl: String = "https://coopsoliserv.imagineapps.co/";
  //public baseUrl: String = "http://localhost:8081/";
  constructor(public http: Http,
    private authService: AuthService, 
    private toastrService: NbToastrService) {
      this.headers = new Headers();
      this.headers.append("Content-Type", "application/json");
      this.headers.append("Authorization", this.authService.getToken());
      this.user = JSON.parse(localStorage.getItem("usuario")) || {};
    }


  get2(object: String) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', this.authService.getToken());
    return this.http.get(this.baseUrl + '' + object, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  post2(object: String, data: any) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', this.authService.getToken());
    return this.http
      .post(this.baseUrl + "" + object, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  delete2(object: String) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', this.authService.getToken());
    return this.http
      .delete(this.baseUrl + "" + object, { headers: this.headers })
      .pipe(map(res => res.json()));
  }
 

  put2(object: String, data: any) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', this.authService.getToken());
    return this.http
      .put(this.baseUrl + "" + object, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }
    /**
   * Opens a Nebular Dialog
   * @param  {any} dialog Reference to the template
   * containing the dialog. Must be type ElementRef or TypeReference
   * @returns void
   */
  showToastr({
    position,
    title,
    message,
    status,
  }: {
    position?: any;
    title: string;
    message?: string;
    status: any;
  }): void {
    let realPosition = position ? position : "top-end";
    let realMessage = message ? message : "";
    let duractionMilisec = 4500;
    this.toastrService.show(`${realMessage}`, `${title}`, {
      position: realPosition,
      status,
      duration: duractionMilisec,
    });
  }

  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
  get({ api }: { api: String }) {
    return this.http
      .get(`${this.baseUrl}${api}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  post({ api, data }: { api: String; data: any }) {
    return this.http
      .post(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }
}
